import { useState,useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { post } from '../../utils/axios'
import { Box, Button, Chip, Divider, Fade, Grid, Modal, Typography } from '@mui/material'
import { toast } from 'react-toastify'

import './Roles.css'

const Roles = () => {

    const {id} = useParams()
    const [isLoading,setIsLoading] = useState(true)
    const [game,setGame] = useState({})
    const [showCardModal,setShowCardModal] = useState(false)
    const [currentRole,setCurrentRole] = useState({})

    useEffect(() => {
        const postedData = {
            id
        }
        post('roles/get-all',postedData).then(({data}) => {
            if(data.status)
                setGame(data.result)
        }).finally(() => setIsLoading(false))
    },[])

    const handleRoleModal = id => {
        const findRole = game.roles.find(role => role._id === id)
        if(!findRole)
            return toast.error('نقش انتخابی یافت نشد')
        setCurrentRole(findRole)
        setShowCardModal(true)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '92%',
        boxSizing: 'border-box',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24
    }

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>آموزش نقش های : {game?.gameType?.title}</h4>
                {isLoading ? <div className="main-area">
                    <Box sx={{ mb: 9 }}>
                        <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مافیایی" /></Divider>
                        <div className="start-roles-placeholder-parent">
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                        </div>
                        <Divider sx={{ mb: 1.5 }}><Chip label="نقش های شهروندی" /></Divider>
                        <div className="start-roles-placeholder-parent">
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                        </div>
                        <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مستقل" /></Divider>
                        <div className="start-roles-placeholder-parent">
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                            <div className="start-roles-placeholder animate"></div>
                        </div>
                    </Box>
                    </div> : <div className="main-area">
                    <Box sx={{ mb: 9 }}>
                        <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مافیایی" /></Divider>
                        <Grid container spacing={1.2} className="side-roles-parent">
                            {game.roles.filter(role => role.side == 'mafia').map(role => <Grid item xs={4} key={role._id} onClick={() => handleRoleModal(role._id)}>
                                <div className="side-role-item mafia">
                                    <i className="fal fa-user-secret"></i>
                                    <span>{role.title}</span>
                                </div>
                            </Grid>)}
                        </Grid>
                        <Divider sx={{ mb: 1.5 }}><Chip label="نقش های شهروندی" /></Divider>
                        <Grid container spacing={1.2} className="side-roles-parent">
                            {game.roles.filter(role => role.side == 'citizen').map(role => <Grid item xs={4} key={role._id} onClick={() => handleRoleModal(role._id)}>
                                <div className="side-role-item citizen">
                                    <i className="fal fa-user"></i>
                                    <span>{role.title}</span>
                                </div>
                            </Grid>)}
                        </Grid>
                        {game.roles.filter(role => role.side == 'independent').length ? <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مستقل" /></Divider> : ''}
                        {game.roles.filter(role => role.side == 'independent').length ? <Grid container spacing={1.2} className="side-roles-parent">
                            {game.roles.filter(role => role.side == 'independent').map(role => <Grid item xs={4} key={role._id} onClick={() => handleRoleModal(role._id)}>
                                <div className="side-role-item independent">
                                    <i className="fal fa-user-alien"></i>
                                    <span>{role.title}</span>
                                </div>
                            </Grid>)}
                        </Grid>: ''}
                    </Box>
                    <Modal open={showCardModal} onClose={() => setShowCardModal(false)} className="role-modal-box">
                        <Fade in={showCardModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>نقش انتخابی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <Typography variant="h6" component="h3" sx={{ fontSize: 20,fontWeight: 'bold',textAlign: 'center',color: '#33487c',mb: 1 }}>{currentRole.title}</Typography>
                                <p className="role-description">{currentRole.description}</p>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={() => setShowCardModal(false)}>متوجه شدم</Button>
                            </Box>
                        </Fade>
                    </Modal>
                </div>}
            </div>
        </div>
    )
}

export default Roles