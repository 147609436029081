import { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { post } from '../../utils/axios'
import { Alert, Box, Button } from '@mui/material'

const Games = () => {

    const navigate = useNavigate()

    const [loading,setLoading] = useState(true)
    const [games,setGames] = useState([])

    useEffect(() => {
        // const postedData = {
        //     page: 1,
        //     limit: 10
        // }
        post('games'/*,postedData*/).then(({data}) => {
            if(data.status)
                setGames(data.result)
        }).finally(() => setLoading(false))
    },[])

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>بازی ها</h4>
                <div className="main-area">
                    <Box sx={{ mb: 6 }}>
                        {loading ? [...Array(3)].map((item,index) => <div className="game-type-placehoder animate" key={`game-${index}`}></div>) : (games.length ? games.map(game => <Button fullWidth variant="contained" className="game-row" key={game._id} sx={{ mb: 2 }} onClick={() => navigate(`/games/${game._id}`)}>
                            <h5>{game.title}</h5>
                            {game.hasEnd ? <span className="game-has-end">تمام شده</span> : <span className="game-has-end white">باز</span>}
                        </Button>) : <Alert variant="standard" color="info">بازی یافت نشد</Alert>)}
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Games