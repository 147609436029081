import { Route,Routes,useNavigate,useLocation } from 'react-router-dom'
import { history } from './utils/history'

// Layouts
import GlobalLayout from './layouts/GlobalLayout'
import AuthLayout from './layouts/AuthLayout'
import AppLayout from './layouts/AppLayout'
import GuestLayout from './layouts/GuestLayout'

// Auth
import AuthIndex from './screens/Auth/Index'
import Login from './screens/Auth/Login'
import Register from './screens/Auth/Register'
import Verify from './screens/Auth/Verify'
// App
import Home from './screens/Home/Home'
// Games
import Games from './screens/Games/Games'
import Game from './screens/Games/Game'
import GameConfig from './screens/Games/Config'
import GameStart from './screens/Games/Start'
// Tutorials
import Tutorials from './screens/Tutorials/Tutorials'
import TutorialsRoles from './screens/Tutorials/Roles'
// GameTypes
import GameTypes from './screens/GameTypes/GameTypes'
// Reserves
import Reserves from './screens/Reserves/Reserves'
// Settings
import Settings from './screens/Settings/Settings'
// Not Found
import NotFound from './components/NotFound'
// Guest
import Guest from './screens/Guest/Guest'
import GuestConfig from './screens/Guest/GuestConfig'
import GuestStart from './screens/Guest/GuestStart'

const App = () => {
  
  history.navigate = useNavigate()
  history.location = useLocation()

  return (
    <Routes>
      <Route path="/" element={<GlobalLayout />}>
        <Route path="/" element={<AppLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/game-types" element={<GameTypes />} />
          <Route path="/games" element={<Games />} />
          <Route path="/games/:id" element={<Game />} />
          <Route path="/games/config/:id" element={<GameConfig />} />
          <Route path="/games/start" element={<GameStart />} />
          <Route path="/tutorials" element={<Tutorials />} />
          <Route path="/tutorials/roles/:id" element={<TutorialsRoles />} />
          <Route path="/reserves" element={<Reserves />} />
          <Route path="/settings" element={<Settings />} />
        </Route>
        <Route path="auth">
          <Route path="/auth" element={<AuthIndex />} />
        </Route>
        <Route path="auth" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="verify" element={<Verify />} />
        </Route>
        <Route path="guest" element={<GuestLayout />}>
          <Route path="/guest" element={<Guest />} />
          <Route path="/guest/:id" element={<GuestConfig />} />
          <Route path="start" element={<GuestStart />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App