import { useEffect,useState } from 'react'
import { Grid,Box,Divider,Avatar,List,ListItem,ListItemAvatar,ListItemText,IconButton,Card,Chip,Button,Modal,Fade,Typography,FormControl,InputLabel,Select,MenuItem,Accordion,AccordionSummary,AccordionDetails,Alert } from '@mui/material'
import { useParams,useNavigate } from 'react-router-dom'
import { post } from '../../utils/axios'
import { toast } from 'react-toastify'

import './Game.css'

const Game = () => {

    const navigate = useNavigate()

    const { id } = useParams()
    const [isLoading,setIsLoading] = useState(true)
    const [game,setGame] = useState({})
    const [playersRoles,setPlayersRoles] = useState([])
    const [cityStatus,setCityStatus] = useState('')
    const [openEndGameModal,setOpenEndGameModal] = useState(false)
    const [cards,setCards] = useState([])
    const [showCardModal,setShowCardModal] = useState(false)
    const [currentCard,setCurrentCard] = useState({})
    const [winner,setWinner] = useState('')
    const [openGameStatusModal,setOpenGameStatusModal] = useState(false)

    useEffect(() => {
        post('games',{id}).then(({data}) => {
            if(data.status){
                setGame(data.result.game)
                setPlayersRoles(data.result.players)
                setCards(data.result.game.lastMoveCards || [])
            }else
                navigate('/')
        }).finally(() => {
            setIsLoading(false)
        })
    },[])

    useEffect(() => {
        const allPlayers = playersRoles.filter(role => role.status == 1).length
        const mafia = playersRoles.filter(role => role.side == 'mafia' && role.status == 1).length * 2
        const independent = playersRoles.filter(role => role.side == 'independent' && role.status == 1).length
        const result = allPlayers - (mafia + independent)
        if(result >= 5)
            setCityStatus('white')
        else if(result == 3 || result == 4)
            setCityStatus('yellow')
        else
            setCityStatus('red')
    },[playersRoles])

    const changePlayerStatus = playerId => {
        const postedData = {
            playerId
        }
        const oldPlayers = [...playersRoles]
        const index = oldPlayers.indexOf(playerId)
        if(index && oldPlayers[index]){
            oldPlayers[index].status = oldPlayers[index].status == 0 ? 1 : 0
            setPlayersRoles(oldPlayers)
        }
        post('games/update-player',postedData).then(({data}) => {
            if(data.status)
                setPlayersRoles(data.result.players)
        })
    }

    const handleEndGame = () => {
        const postedData = {
            id,
            hasEnd: true,
            winner
        }
        post('games/update',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/')
            }
        }).finally(() => {
            setOpenEndGameModal(false)
        })
    }

    const openCardModal = () => {
        const availableCards = [...cards.filter(card => card.status == 1)]
        const index = Math.floor(Math.random() * availableCards.length)
        const card = availableCards[index]
        setCurrentCard(card)
        availableCards[index].status = 0
        setShowCardModal(true)
        const postedData = {
            gameId: id,
            cardId: card._id
        }
        post('games/update-card',postedData)
    }

    const getPlayersList = () => {
        if(navigator.share){
            let players = ''
            playersRoles.map(player => players += `\r\n${player.name}`)
            navigator.share({
                title: 'لیست بازیکنان',
                text: 'لیست بازیکنان' + '\r\n' + players
            })
        }else
            toast.warning('این عملیات فقط در موبایل امکان پذیر است')
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '92%',
        boxSizing: 'border-box',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24
    }

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4 onClick={getPlayersList} className="players-list-title">لیست بازیکنان</h4>
                <span id="game-status" onClick={() => setOpenGameStatusModal(true)} className={cityStatus}>وضعیت شهر</span>
                <Modal open={openGameStatusModal} onClose={() => setOpenGameStatusModal(false)} className="role-modal-box">
                    <Fade in={openGameStatusModal}>
                        <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                            <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>وضعیت بازی</Typography>
                            <Divider sx={{ mt: 2,mb: 2 }} />
                            <p className="role-description custom-line-height">تعداد کل شهروندان : {playersRoles.filter(player => player.side == 'citizen').length}</p>
                            <p className="role-description custom-line-height">تعداد کل شهروندان زنده : {playersRoles.filter(player => player.side == 'citizen' && player.status == 1).length}</p>
                            <p className="role-description custom-line-height">تعداد کل شهروندان از بازی خارج شده : {playersRoles.filter(player => player.side == 'citizen' && player.status == 0).length}</p>
                            <Divider sx={{ mt: 2,mb: 2 }} />
                            <p className="role-description custom-line-height">تعداد کل مافیا : {playersRoles.filter(player => player.side == 'mafia').length}</p>
                            <p className="role-description custom-line-height">تعداد کل مافیای زنده : {playersRoles.filter(player => player.side == 'mafia' && player.status == 1).length}</p>
                            <p className="role-description custom-line-height">تعداد کل مافیای از بازی خارج شده : {playersRoles.filter(player => player.side == 'mafia' && player.status == 0).length}</p>
                            {playersRoles.filter(player => player.side == 'independent').length ? <Divider sx={{ mt: 2,mb: 2 }} /> : ''}
                            {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل ها : {playersRoles.filter(player => player.side == 'independent').length}</p> : ''}
                            {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل های زنده : {playersRoles.filter(player => player.side == 'independent' && player.status == 1).length}</p> : ''}
                            {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل های از بازی خارج شده : {playersRoles.filter(player => player.side == 'independent' && player.status == 0).length}</p> : ''}
                            <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={() => setOpenGameStatusModal(false)}>متوجه شدم</Button>
                        </Box>
                    </Fade>
                </Modal>
                {!game.hasEnd ? <Button id="handle-end-game" onClick={() => setOpenEndGameModal(true)}>اتمام بازی</Button> : ''}
                <div className="main-area">
                    {Object.keys(game).length ? <div id="game-details">
                        <ul>
                            <li>سبک بازی : <span>{game.title ? game.title.split(' - ')[0] : ''}</span></li>
                            <li>تعداد بازیکن ها : <span>{playersRoles.length} نفر</span></li>
                            <li>وضعیت بازی : <span>{game.hasEnd ? 'پایان یافته' : 'باز'}</span></li>
                            {game.hasEnd ? <li>برنده بازی : <span>{game.winner == 'citizen' ? 'شهروندان' : (game.winner == 'mafia' ? 'مافیا' : 'مستقل')}</span></li> : ''}
                        </ul>
                    </div> : ''}
                    {cards.length && !game.hasEnd ? <Divider sx={{ mb: 2 }}><Chip label="کارت های حرکت آخر" /></Divider> : ''}
                    {cards.length && !game.hasEnd ? <div id="last-move-cards"><Accordion>
                        <AccordionSummary expandIcon={<i className="fal fa-chevron-down"></i>} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography>لیست کارت ها</Typography>
                        </AccordionSummary>
                        <Divider sx={{ mb: 1 }} />
                        <AccordionDetails>
                            {cards.filter(card => card.status).length ? <Grid container spacing={2}>
                                {cards.filter(card => card.status == 1).map((card,index) => <Grid key={index} item xs={3}><Button fullWidth className="role-btn" sx={{ py: 1.5 }} variant="contained" onClick={openCardModal}>{index + 1}</Button></Grid>)}
                            </Grid> : <Alert variant="standard" color="info">کارتی باقی نمانده است</Alert>}
                        </AccordionDetails>
                    </Accordion></div> : ''}
                    {isLoading ? [...Array(5)].map((item,index) => <div className="game-type-placehoder animate" key={`game-${index}`}></div>) : <Box sx={{ mb: !game.hasEnd ? 13 : 8 }}>
                        <Divider sx={{ mb: 2 }}><Chip label={`اعضای تیم مافیا (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'mafia' && player.status == 1).length})`} /></Divider>
                        <Grid item xs={12} md={6}>
                            <List sx={{ p: 0 }}>
                                {playersRoles.filter(player => player.side == 'mafia').sort((a,b) => a.sort - b.sort).map(player => <Card key={`mafia-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`mafia-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                    <ListItem secondaryAction={!game.hasEnd ? <IconButton sx={{ ml: 2 }} edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton> : ''}>
                                        <ListItemAvatar><Avatar><i className="fal fa-user-secret"></i></Avatar></ListItemAvatar>
                                        <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                    </ListItem>
                                </Card>)}
                            </List>
                        </Grid>
                        {playersRoles.filter(player => player.side == 'citizen').length ? <Divider sx={{ my: 2 }}><Chip label={`اعضای تیم شهر (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'citizen' && player.status == 1).length})`} /></Divider> : ''}
                        <Grid item xs={12} md={6}>
                            <List sx={{ p: 0 }}>
                                {playersRoles.filter(player => player.side == 'citizen').sort((a,b) => a.sort - b.sort).map(player => <Card key={`citizen-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`citizen-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                    <ListItem secondaryAction={!game.hasEnd ? <IconButton edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton> : ''}>
                                        <ListItemAvatar><Avatar><i className="fal fa-user"></i></Avatar></ListItemAvatar>
                                        <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                    </ListItem>
                                </Card>)}
                            </List>
                        </Grid>
                        {playersRoles.filter(player => player.side == 'independent').length ? <Divider sx={{ my: 2 }}><Chip label={`اعضای ساید مستقل (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'independent' && player.status == 1).length})`} /></Divider> : ''}
                        {playersRoles.filter(player => player.side == 'independent').length ? <Grid item xs={12} md={6}>
                            <List sx={{ p: 0 }}>
                                {playersRoles.filter(player => player.side == 'independent').sort((a,b) => a.sort - b.sort).map(player => <Card key={`independent-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`independent-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                    <ListItem secondaryAction={!game.hasEnd ? <IconButton edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton> : ''}>
                                        <ListItemAvatar><Avatar><i className="fal fa-user-alien"></i></Avatar></ListItemAvatar>
                                        <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                    </ListItem>
                                </Card>)}
                            </List>
                        </Grid> : ''}
                    </Box>}
                    <Modal open={openEndGameModal} onClose={() => setOpenEndGameModal(false)} className="role-modal-box">
                        <Fade in={openEndGameModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>اتمام بازی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <FormControl fullWidth>
                                    <InputLabel>برنده بازی</InputLabel>
                                    <Select value={winner} onChange={e => setWinner(e.target.value)} label="برنده بازی">
                                        <MenuItem value="citizen">شهروندان</MenuItem>
                                        <MenuItem value="mafia">مافیا</MenuItem>
                                        <MenuItem value="independent">مستقل</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={handleEndGame} disabled={!winner}>ذخیره اطلاعات</Button>
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal open={showCardModal} onClose={() => setShowCardModal(false)} className="role-modal-box">
                        <Fade in={showCardModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>کارت انتخابی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <Typography variant="h6" component="h3" sx={{ fontSize: 20,fontWeight: 'bold',textAlign: 'center',color: '#33487c',mb: 1 }}>{currentCard.title}</Typography>
                                <p className="role-description">{currentCard.description}</p>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default Game