import axios from 'axios'
import { toast } from 'react-toastify';
import { history } from './history';

const baseUrl = 'https://mafiabaza.com/api/v1/app/';

const get = async url => {
    const result = await axios.get(baseUrl + url,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }).catch(error => {
        if(error.response.status == 401){
            localStorage.removeItem('token')
            history.navigate('/auth')
        }
    })
    if(result && result.data && !result.data.status)
        result.data.error.map(e => toast.error(e))
    return result
}
const post = async (url,postedData = {}) => {
    const result = await axios.post(baseUrl + url,postedData,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    }).catch(error => {
        if(error.response.status == 401){
            localStorage.removeItem('token')
            history.navigate('/auth')
        }
    })
    if(result && result.data && !result.data.status)
        result.data.error.map(e => toast.error(e))
    return result
}

export {
    get,
    post
}