import { useState,useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Box, Button, Tab, Tabs } from '@mui/material'
import { post } from '../../utils/axios'

import './Tutorials.css'

const Tutorials = () => {
    
    const navigate = useNavigate()

    const [tab,setTab] = useState(0)
    const [gameTypesLoading,setGameTypesLoading] = useState(true)
    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {
        post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
        }).finally(() => setGameTypesLoading(false))
    },[])

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>آموزش ها</h4>
                <div id="tutorials-box">
                    <Box sx={{ borderBottom: 1,orderColor: 'divider',borderBottomColor: 'rgba(255,255,255,0.2)',mb: 3 }}>
                        <Tabs value={tab} onChange={(e,newValue) => setTab(newValue)} centered>
                            <Tab label="نقش ها" />
                            <Tab label="سبک ها" />
                            <Tab label="فکت ها" />
                        </Tabs>
                    </Box>
                </div>
                <div className="main-area tutorials-main-area">
                    {tab == 0 ? <>
                        <h4>انتخاب سبک</h4>
                        <div id="game-types">
                            {gameTypesLoading ? [...Array(3)].map((item,index) => <div className="game-type-placehoder animate" key={`game-type-${index}`}></div>) : gameTypes.map(gameType => <Button fullWidth variant="contained" className="game-type" key={gameType._id} sx={{ mb: 2 }} onClick={() => navigate(`/tutorials/roles/${gameType._id}`)}>
                                <img src={gameType.image ? `https://mafiabaza.com${gameType.image}` : '/assets/img/default-game-type.jpg'} />
                                <h5>{gameType.title}</h5>
                            </Button>)}
                        </div>
                    </> : ''}
                    {tab == 1 ? <Alert variant="standard" color="warning">به زودی آموزش های این قسمت تکمیل می شود</Alert> : ''}
                    {tab == 2 ? <Alert variant="standard" color="warning">به زودی آموزش های این قسمت تکمیل می شود</Alert> : ''}
                </div>
            </div>
        </div>
    )
}

export default Tutorials