import { Button } from '@mui/material'
import './UpdateModal.css'

const UpdateModal = () => {

    const updateApp = () => {
        if('caches' in window)
            caches.keys().then(names => {
                names.forEach(name => {
                    caches.delete(name)
                })
                window.location.reload()
            })
    }

    return (
        <div id="update-modal">
            <div id="update-modal-content">
                <h3>به روزرسانی !</h3>
                <p>کاربر گرامی ، نسخه جدیدی از اپلیکیشن هم اکنون در دسترس می‌باشد . لطفاً هم اکنون اپلیکیشن خود را به روزرسانی کنید</p>
                <Button fullWidth variant="contained" id="update-app-btn" onClick={updateApp}>به روزرسانی</Button>
            </div>
        </div>
    )
}

export default UpdateModal