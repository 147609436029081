import { useEffect,useState } from 'react'
import { Outlet } from 'react-router-dom'
import SplashScreen from '../components/SplashScreen'
import AddToHomeScreen from '../components/AddToHomeScreen'

import './GlobalLayout.css'

const GlobalLayout = () => {

    const [showSplashScreen,setShowSplashScreen] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setShowSplashScreen(false)
        },3000)
    },[])

    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

    return (
        <>
        {isIOS && !window.navigator.standalone ? <AddToHomeScreen /> : <>
            {showSplashScreen && <SplashScreen />}
            <Outlet />
        </>}
        </>
    )
}

export default GlobalLayout