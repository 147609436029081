import { useContext,useState } from 'react'
import { Box, Button, Divider, Fade, FormControl, Grid, InputLabel, Menu, MenuItem, Modal, Select, Typography } from '@mui/material'
import { useNavigate,useLocation } from 'react-router-dom'
import { Context } from '../../context/Context'

import './Header.css'
import { post } from '../../utils/axios'

const Header = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const context = useContext(Context)

    const [sortMenuOpen,setSortMenuOpen] = useState(false)
    const [openTimerModal,setOpenTimerModal] = useState(false)
    const [turnTime,setTurnTime] = useState('30')
    const [turnTimer,setTurnTimer] = useState(0)
    const [turnInterval,setTurnInterval] = useState(() => {})
    const [challangeTime,setChallangeTime] = useState('25')
    const [challangeTimer,setChallangeTimer] = useState(0)
    const [challangeInterval,setChallangeInterval] = useState(() => {})
    const open = Boolean(sortMenuOpen)

    const handleClick = e => {
        setSortMenuOpen(e.currentTarget)
    }

    const handleClose = () => {
        setSortMenuOpen(null)
    }

    const handleMenu = url => {
        setSortMenuOpen(false)
        navigate(url)
    }

    const logout = () => {
        post('init/logout')
        localStorage.removeItem('token')
        setTimeout(() => {
            navigate('/auth')
        },300)
    }

    const playSound = (type = 'single') => {
        const single = new Audio(`/assets/bells/${type}.mp3`)
        single.play()
    }

    const handleTurn = () => {
        if(turnTimer == 0){
            setTurnTimer(parseInt(turnTime))
            let interCounter = parseInt(turnTime)
            const interval = setInterval(() => {
                if(interCounter > 0){
                    interCounter -= 1
                    setTurnTimer(interCounter)
                }
                if(interCounter == 5)
                    playSound('single')
                if(interCounter == 0){
                    playSound('triple')
                    clearInterval(interval)
                }
            },1000)
            setTurnInterval(interval)
        }else{
            setTurnTimer(0)
            if(turnInterval){
                clearInterval(turnInterval)
                setTurnInterval(() => {})
            }
        }
    }

    const handleChallange = () => {
        if(challangeTimer == 0){
            setChallangeTimer(parseInt(challangeTime))
            let interCounter = parseInt(challangeTime)
            const interval = setInterval(() => {
                if(interCounter > 0){
                    interCounter -= 1
                    setChallangeTimer(interCounter)
                }
                if(interCounter == 5)
                    playSound('single')
                if(interCounter == 0){
                    playSound('triple')
                    clearInterval(interval)
                }
            },1000)
            setChallangeInterval(interval)
        }else{
            setChallangeTimer(0)
            if(challangeInterval){
                clearInterval(challangeInterval)
                setChallangeInterval(() => {})
            }
        }
    }

    const handleContextData = () => {
        if(context.user.phone == '09126646053' || context.user.phone == '09127614901' || context.user.phone == '09198069156')
            context.setAvatarClicked(true)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '92%',
        boxSizing: 'border-box',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24
    }

    return (
        <div id="app-header">
            <div id="header-profile">
                <img src="/assets/img/avatar.jpg" onClick={() => handleContextData()} />
                <h2 style={{color: '#fff'}}>{context.user.name}</h2>
                {location.pathname == '/' ? <button onClick={handleClick} className="header-bars-btn"><i className="fad fa-bars"></i></button> : <button onClick={() => navigate(-1)} className="header-bars-btn"><i className="fad fa-arrow-left"></i></button>}
                <button onClick={() => setOpenTimerModal(true)} className="header-time-btn"><i className="fad fa-alarm-clock"></i></button>
                <Menu anchorEl={sortMenuOpen} open={open} onClose={handleClose}>
                    <MenuItem onClick={() => handleMenu('/games')}>بازی ها</MenuItem>
                    <MenuItem onClick={logout}>خروج</MenuItem>
                </Menu>
                <Modal open={openTimerModal} onClose={() => setOpenTimerModal(false)} className="role-modal-box">
                    <Fade in={openTimerModal}>
                        <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                            <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>تایمر نوبت {turnTimer != 0 ? `(${turnTimer} ثانیه)` : ''}</Typography>
                            <Divider sx={{ mt: 2,mb: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel>زمان</InputLabel>
                                        <Select value={turnTime} onChange={e => setTurnTime(e.target.value)} label="زمان">
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="25">25</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value="40">40</MenuItem>
                                            <MenuItem value="50">50</MenuItem>
                                            <MenuItem value="60">60</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth color={!turnTimer ? 'primary' : 'error'} variant="contained" sx={{ py: 1.95 }} disabled={!turnTime} onClick={handleTurn}>{!turnTimer ? 'شروع' : 'اتمام'}</Button>
                                </Grid>
                            </Grid>
                            <Typography variant="h6" component="h3" sx={{ mt: 2,fontSize: 15 }}>تایمر چالش {challangeTimer != 0 ? `(${challangeTimer} ثانیه)` : ''}</Typography>
                            <Divider sx={{ mt: 2,mb: 2 }} />
                            <Grid container spacing={2}>
                                <Grid item xs={8}>
                                    <FormControl fullWidth>
                                        <InputLabel>زمان</InputLabel>
                                        <Select value={challangeTime} onChange={e => setChallangeTime(e.target.value)} label="زمان">
                                            <MenuItem value="20">20</MenuItem>
                                            <MenuItem value="25">25</MenuItem>
                                            <MenuItem value="30">30</MenuItem>
                                            <MenuItem value="40">40</MenuItem>
                                            <MenuItem value="50">50</MenuItem>
                                            <MenuItem value="60">60</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button fullWidth color={!challangeTimer ? 'primary' : 'error'} variant="contained" sx={{ py: 1.95 }} disabled={!challangeTime} onClick={handleChallange}>{!challangeTimer ? 'شروع' : 'اتمام'}</Button>
                                </Grid>
                            </Grid>
                            <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={() => setOpenTimerModal(false)}>بستن تایمر</Button>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}

export default Header