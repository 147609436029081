import { Outlet,Navigate } from 'react-router-dom'
import { Container } from '@mui/material'

import './AuthLayout.css'

const AuthLayout = () => {

    return (
        <div id="auth-layout">
            <img src="/assets/img/logo-bg.png" />
            <div id="auth-layout-title">
                <h1>مافیابازا</h1>
                <a href="https://instagram.com/mafiabaza_com" target="_blank"><i className="fab fa-instagram"></i></a>
                <a href="https://t.me/mafiabaza_com" target="_blank"><i className="fab fa-telegram"></i></a>
            </div>
            <div id="auth-content">
                <Container>
                    {!localStorage.getItem('token') ? <Outlet /> : <Navigate to="/" />}
                </Container>
            </div>
        </div>
    )
}

export default AuthLayout