import { useNavigate } from 'react-router-dom'

import './NotFound.css'

const NotFound = () => {

    const navigate = useNavigate()

    return (
        <div id="not-found">
            <img src="/assets/img/logo.png" />
            <h5>404 | یافت نشد :(</h5>
            <button onClick={() => navigate('/')} className="header-time-btn not-found-custom-btn"><i className="fad fa-home"></i></button>
            <button onClick={() => navigate(-1)} className="header-time-btn not-found-custom-btn"><i className="fad fa-arrow-left"></i></button>
        </div>
    )
}

export default NotFound