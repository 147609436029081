import { createContext,useEffect,useState } from 'react'

const Context = createContext({
    user: {},
    gameConfig: {},
    setUser: () => {},
    setGameConfig: () => {}
})

const ContextProvider = ({ children }) => {
    const [user,setUser] = useState({})
    const [gameConfig,setGameConfig] = useState({})
    const [avatarClicked,setAvatarClicked] = useState({})
    
    return (
        <Context.Provider value={{user,gameConfig,setUser,setGameConfig,avatarClicked,setAvatarClicked}}>{children}</Context.Provider>
    )
}
const ContextConsumer = ({ children }) => (
    <Context.Consumer>{children}</Context.Consumer>
)

export {
    Context,
    ContextProvider,
    ContextConsumer
}