import { Outlet,useLocation,useNavigate } from 'react-router-dom'
import { Container } from '@mui/material'

const GuestLayout = () => {

    const navigate = useNavigate()
    const location = useLocation()

    return (
        <Container>
            <div id="app-header">
                <div id="header-profile">
                    <img src="/assets/img/avatar.jpg"/>
                    <h2 style={{color: '#fff'}}>مهمان مافیابازا</h2>
                    <button onClick={() => location.pathname == '/guest' ? navigate('/auth') : navigate(-1)} className="header-bars-btn"><i className="fad fa-arrow-left"></i></button>
                </div>
            </div>
            <Outlet />
        </Container>
    )
}

export default GuestLayout