import { useEffect,useState,useContext } from 'react'
import { useParams,useNavigate } from 'react-router-dom'
import { FormControlLabel,Switch,Grid,Divider,Chip,Radio,RadioGroup,Button,Box } from '@mui/material'
import { post } from '../../utils/axios'
import { Context } from '../../context/Context'

const GuestConfig = () => {

    const context = useContext(Context)
    const navigate = useNavigate()

    const {id} = useParams()
    const [isLoading,setIsLoading] = useState(true)
    const [game,setGame] = useState({gameType: {},roles: [],lastMoveCards: []})
    const [roles,setRoles] = useState([])
    const [mafia,setMafia] = useState(0)
    const [citizen,setCitizen] = useState(0)
    const [independent,setIndependent] = useState(0)

    useEffect(() => {
        const postedData = {
            id
        }
        post('roles/get-all',postedData).then(({data}) => {
            if(data.status)
                setGame(data.result)
        }).finally(() => setIsLoading(false))
    },[])

    useEffect(() => {
        if(game && game.roles){
            const defaultRoles = []
            let citizenCount = 0
            let mafiaCount = 0
            game.roles.map(role => {
                if(role.defaultChecked){
                    if(role.side == 'citizen')
                        citizenCount++
                    if(role.side == 'mafia')
                        mafiaCount++
                    defaultRoles.push(role._id)
                }
                if(role.defaultChecked && role.countable && role.side == 'citizen'){
                    if(role.side == 'citizen')
                        citizenCount++
                    if(role.side == 'mafia')
                        mafiaCount++
                    defaultRoles.push(role._id)
                }
            })
            setCitizen(citizenCount)
            setMafia(mafiaCount)
            setRoles(defaultRoles)
        }
    },[game])

    const handleCount = (checked,side,length = 1) => {
        switch(side){
            case 'citizen': 
                if(checked)
                    setCitizen(citizen + length)
                else
                    setCitizen(citizen - length)
            break
            case 'mafia': 
                if(checked)
                    setMafia(mafia + length)
                else
                    setMafia(mafia - length)
            break
            case 'independent': 
                if(checked)
                    setIndependent(independent + length)
                else
                    setIndependent(independent - length)
            break
        }
    }

    const handleRole = (checked,roleId,side) => {
        if(checked){
            setRoles([...roles,roleId])
            handleCount(true,side)
        }else{
            if(checked === null){
                const oldRoles = [...roles]
                const index = oldRoles.indexOf(roleId)
                setRoles([...oldRoles.filter((r,i) => i != index)])
                handleCount(false,side)
            }else{
                const oldRoles = [...roles]
                const length = oldRoles.filter(r => r == roleId).length
                handleCount(false,side,length)
                setRoles([...oldRoles.filter(r => r != roleId)])
            }
        }
    }

    const startGame = () => {
        const gameConfig = {
            game,
            selectedRoles: roles
        }
        context.setGameConfig(gameConfig)
        navigate('/guest/start')
    }

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>شروع بازی جدید : {game.gameType.title}</h4>
                {isLoading ? <>
                    <Divider sx={{ mb: 2 }}><Chip label="نوع پخش نقش" /></Divider>
                    <div id="start-type">
                        <div className="start-type-placeholder animate"></div>
                        <div className="start-type-placeholder animate"></div>
                    </div>
                    <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مافیایی" /></Divider>
                    <div className="start-roles-placeholder-parent">
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                    </div>
                    <Divider sx={{ mb: 1.5 }}><Chip label="نقش های شهروندی" /></Divider>
                    <div className="start-roles-placeholder-parent">
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                    </div>
                    <Divider sx={{ mb: 1.5 }}><Chip label="نقش های مستقل" /></Divider>
                    <div className="start-roles-placeholder-parent">
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                        <div className="start-roles-placeholder animate"></div>
                    </div>
                </> : <div className="main-area">
                    <Box sx={{ mb: 2 }}>
                        <div className="start-game-part">
                            <div id="start-roles">
                                <Divider sx={{ mb: 1.5 }}><Chip label={`نقش های مافیایی ${mafia ? '(' + mafia + ')' : ''}`} /></Divider>
                                <Grid container className="start-roles-parent" sx={{ mb: 1 }}>
                                    {game.roles.filter(role => role.side == 'mafia').map(role => <Grid key={role._id} item xs={4} sx={{ mb: 2 }} className="start-role-item">
                                        <FormControlLabel sx={{ margin: 0 }} value="bottom" control={<Switch color="primary" checked={roles.includes(role._id)} onChange={e => handleRole(e.target.checked,role._id,role.side)} />} label={role.title} labelPlacement="bottom" />
                                        {role.countable && roles.includes(role._id) && <div className="role-plus-minus-parent">
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#54aa47 !important',color: '#fff' }} onClick={() => handleRole(true,role._id,role.side)}><i className="fal fa-plus"></i></Button>
                                            <span>{roles.filter(r => r == role._id).length}</span>
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#d05252 !important',color: '#fff' }} onClick={() => handleRole(null,role._id,role.side)}><i className="fal fa-minus"></i></Button>
                                        </div>}
                                    </Grid>)}
                                </Grid>
                                <Divider sx={{ mb: 1.5 }}><Chip label={`نقش های شهروندی ${citizen ? '(' + citizen + ')' : ''}`} /></Divider>
                                <Grid container className="start-roles-parent">
                                    {game.roles.filter(role => role.side == 'citizen').map(role => <Grid key={role._id} item xs={4} sx={{ mb: 2 }} className="start-role-item">
                                        <FormControlLabel sx={{ margin: 0 }} value="bottom" control={<Switch color="primary" checked={roles.includes(role._id)} onChange={e => handleRole(e.target.checked,role._id,role.side)} />} label={role.title} labelPlacement="bottom" />
                                        {role.countable && roles.includes(role._id) && <div className="role-plus-minus-parent">
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#54aa47 !important',color: '#fff' }} onClick={() => handleRole(true,role._id,role.side)}><i className="fal fa-plus"></i></Button>
                                            <span>{roles.filter(r => r == role._id).length}</span>
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#d05252 !important',color: '#fff' }} onClick={() => handleRole(null,role._id,role.side)}><i className="fal fa-minus"></i></Button>
                                        </div>}
                                    </Grid>)}
                                </Grid>
                                {game.roles.filter(role => role.side == 'independent').length ? <Divider sx={{ mb: 1.5 }}><Chip label={`نقش های مستقل ${independent ? '(' + independent + ')' : ''}`} /></Divider> : ''}
                                <Grid container className="start-roles-parent">
                                    {game.roles.filter(role => role.side == 'independent').map(role => <Grid key={role._id} item xs={4} sx={{ mb: 2 }} className="start-role-item">
                                        <FormControlLabel sx={{ margin: 0 }} value="bottom" control={<Switch color="primary" checked={roles.includes(role._id)} onChange={e => handleRole(e.target.checked,role._id,role.side)} />} label={role.title} labelPlacement="bottom" />
                                        {role.countable && roles.includes(role._id) && <div className="role-plus-minus-parent">
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#54aa47 !important',color: '#fff' }} onClick={() => handleRole(true,role._id,role.side)}><i className="fal fa-plus"></i></Button>
                                            <span>{roles.filter(r => r == role._id).length}</span>
                                            <Button variant="contained" color="success" sx={{ py:1,backgroundColor: '#d05252 !important',color: '#fff' }} onClick={() => handleRole(null,role._id,role.side)}><i className="fal fa-minus"></i></Button>
                                        </div>}
                                    </Grid>)}
                                </Grid>
                            </div>
                        </div>
                        {roles.length ? <Button fullWidth sx={{ py: 1.5,mt: 1.5 }} variant="contained" className="btn-primary" onClick={startGame}>ثبت و شروع بازی <span className="roles-count">( تعداد نقش ها : {roles.length} )</span></Button> : ''}
                    </Box>
                </div>}
            </div>
        </div>
    )
}

export default GuestConfig