import './SplashScreen.css'

const SplashScreen = () => {
    return (
        <div id="splash-screen">
            <div id="splash-logo-parent">
                <img src="/assets/img/logo.png"  />
            </div>
        </div>
    )
}

export default SplashScreen