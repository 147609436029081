import { useEffect,useContext,useState } from 'react'
import { Outlet,Navigate } from 'react-router-dom'
import { Container } from '@mui/material'
import BottomNavigationBar from './partials/BottomNavigationBar'
import { get } from '../utils/axios'
import { Context } from '../context/Context'
import Header from './partials/Header'
import UpdateModal from '../components/UpdateModal'

const AppLayout = () => {

    const context = useContext(Context)
    const [showUpdateModal,setShowUpdateModal] = useState(false)

    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

    useEffect(() => {
        if(localStorage.getItem('token')){
            get('init/profile').then(({data}) => {
                if(data.status)
                    context.setUser(data.result)
            })
            get('init/version').then(({data}) => {
                if(data.status){
                    if(data.version > 1.6)
                        setShowUpdateModal(true)
                }
            })
        }
    },[])

    return (
        <Container className={isIOS ? 'ios-device' : ''}>
            {showUpdateModal && <UpdateModal />}
            <Header />
            {localStorage.getItem('token') ? <Outlet /> : <Navigate to="/auth" />}
            <BottomNavigationBar />
        </Container>
    )
}

export default AppLayout