import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

import './Index.css'

const Index = () => {

    const navigate = useNavigate()

    useEffect(() => {
        if(localStorage.getItem('token'))
            navigate('/')
    },[])

    return (
        <div id="auth-index">
            <div id="auth-box">
                <img src="/assets/img/logo.png" />
                <h2>خوش آمدید</h2>
                <div><h1>مافیابازا</h1><p>، بزرگترین وب اپلیکیشن گردانندگی بازی مافیا</p></div>
                <Button onClick={() => navigate('/auth/login')} variant="contained" className="btn-login" fullWidth sx={{ py: 1.3,mb: 2 }}>ورود</Button>
                <Button onClick={() => navigate('/auth/register')} variant="outlined" className="btn-register" fullWidth sx={{ py: 1.3,mb: 2 }}>عضویت</Button>
                <Button onClick={() => navigate('/guest')} variant="outlined" className="btn-register" fullWidth sx={{ py: 1.3,border: 'unset !important' }}>پخش نقش بدون ورود</Button>
            </div>
        </div>
    )
}

export default Index