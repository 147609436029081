import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, InputAdornment, TextField } from '@mui/material'
import Button from '../../components/Button'
import { post } from '../../utils/axios'
import { toast } from 'react-toastify'

const Register = () => {

    const navigate = useNavigate()

    const [name,setName] = useState('')
    const [phone,setPhone] = useState('')
    const [isLoading,setIsLoading] = useState(false)

    useEffect(() => {
        if(phone && phone.length){
            if(phone.includes('۰'))
                setPhone(phone.replace(/۰/g,'0'))
            if(phone.includes('۱'))
                setPhone(phone.replace(/۱/g,'1'))
            if(phone.includes('۲'))
                setPhone(phone.replace(/۲/g,'2'))
            if(phone.includes('۳'))
                setPhone(phone.replace(/۳/g,'3'))
            if(phone.includes('۴'))
                setPhone(phone.replace(/۴/g,'4'))
            if(phone.includes('۵'))
                setPhone(phone.replace(/۵/g,'5'))
            if(phone.includes('۶'))
                setPhone(phone.replace(/۶/g,'6'))
            if(phone.includes('۷'))
                setPhone(phone.replace(/۷/g,'7'))
            if(phone.includes('۸'))
                setPhone(phone.replace(/۸/g,'8'))
            if(phone.includes('۹'))
                setPhone(phone.replace(/۹/g,'9'))
        }
    },[phone])

    const login = e => {
        e.preventDefault()
        setIsLoading(true)
        const postedData = {
            phone,
            name
        }
        post('auth/register',postedData).then(({data}) => {
            if(data.status){
                localStorage.setItem('phone',postedData.phone)
                localStorage.setItem('type','register')
                toast.success(data.message)
                setTimeout(() => {
                    navigate('/auth/verify')
                },300)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <Grid>
            <form onSubmit={e => login(e)}>
                <h2 className="auth-page-title">عضویت</h2>
                <span className="auth-page-description">در صورتی که از قبل حساب کاربری ندارید ، می‌توانید از این بخش در مافیابازا عضو شوید</span>
                <TextField fullWidth sx={{ mb: 2.5 }} label="نام و نام خانوادگی" InputProps={{startAdornment: (<InputAdornment position="start"><i className="fad fa-user"></i></InputAdornment>)}} variant="standard" value={name} onChange={e => setName(e.target.value)} />
                <TextField fullWidth className="ltr-input-parent" inputProps={{maxLength: 11}} type="tel" label="شماره همراه" InputProps={{startAdornment: (<InputAdornment position="start"><i className="fad fa-mobile"></i></InputAdornment>)}} variant="standard" value={phone} onChange={e => setPhone(e.target.value)} />
                <Button type="submit" fullWidth isLoading={isLoading} sx={{ py: 1.3,mt: 2.5,mb: 1.5 }} className="btn-primary" variant="contained">عضویت</Button>
                <Button type="button" onClick={() => navigate('/auth/login')} variant="text" className="btn-register" fullWidth sx={{ py: 1.3 }}>حساب کاربری دارید ؟ وارد شوید</Button>
            </form>
        </Grid>
    )
}

export default Register