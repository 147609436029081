import { useEffect,useState,useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid,Button,Typography,Modal,Fade,Box,Divider,TextField,Avatar,List,ListItem,ListItemAvatar,ListItemText,IconButton,Card,Chip,Select,MenuItem,FormControl,InputLabel,Accordion,AccordionSummary,AccordionDetails,Alert } from '@mui/material'
import { Context } from '../../context/Context'
import { toast } from 'react-toastify'
import { post } from '../../utils/axios'

import './Start.css'

const Start = () => {

    const context = useContext(Context)
    const navigate = useNavigate()

    const [autoFocus,setAutoFocus] = useState(false)
    const [playerName,setPlayerName] = useState('')
    const [allRoles,setAllRoles] = useState([])
    const [rolesButton,setRolesButton] = useState([])
    const [gameId,setGameId] = useState('')
    const [game,setGame] = useState({})
    const [roles,setRoles] = useState([])
    const [rolesBackup,setRolesBackup] = useState([])
    const [startType,setStartType] = useState('')
    const [open,setOpen] = useState(false)
    const [playersRoles,setPlayersRoles] = useState([])
    const [currentRole,setCurrentRole] = useState({})
    const [finishAssignRoles,setFinishAssignRoles] = useState(false)
    const [isSaved,setIsSaved] = useState(false)
    const [openSwitchModal,setOpenSwitchModal] = useState(false)
    const [currentPlayerId,setCurrentPlayerId] = useState('')
    const [newRole,setNewRole] = useState('')
    const [cityStatus,setCityStatus] = useState('')
    const [openEndGameModal,setOpenEndGameModal] = useState(false)
    const [winner,setWinner] = useState('')
    const [cards,setCards] = useState([])
    const [showCardModal,setShowCardModal] = useState(false)
    const [currentCard,setCurrentCard] = useState({})
    const [openGameStatusModal,setOpenGameStatusModal] = useState(false)

    useEffect(() => {
        if(!Object.keys(context.gameConfig).length)
            return navigate('/')
        setGame(context.gameConfig.game.gameType)
        setAllRoles(context.gameConfig.game.roles)
        setCards(context.gameConfig.lastMoveCards)
        setRoles(context.gameConfig.selectedRoles.sort((a, b) => 0.5 - Math.random()))
        const rolesButtonArr = []
        context.gameConfig.selectedRoles.map((role,index) => rolesButtonArr.push(index + 1))
        setRolesButton(rolesButtonArr)
        setStartType(context.gameConfig.startType)
        context.setGameConfig({})
    },[])

    useEffect(() => {
        if(finishAssignRoles){
            const allPlayers = playersRoles.filter(role => role.status == 1).length
            const mafia = playersRoles.filter(role => role.side == 'mafia' && role.status == 1).length * 2
            const independent = playersRoles.filter(role => role.side == 'independent' && role.status == 1).length
            const result = allPlayers - (mafia + independent)
            if(result >= 5)
                setCityStatus('white')
            else if(result == 3 || result == 4)
                setCityStatus('yellow')
            else
                setCityStatus('red')
        }
    },[playersRoles])

    const startGame = () => {
        const postedData = {
            title: `${game.title} - ${new Date().toLocaleDateString('fa-IR')}`,
            gameType: game._id,
            players: playersRoles,
            lastMoveCards: cards
        }
        let savedToDb = false;
        post('games/create',postedData).then(({data}) => {
            if(data.status){
                setIsSaved(true)
                savedToDb = true
                setGameId(data.result.gameId)
                setPlayersRoles(data.result.players)
                toast.success('بازی جدید با موفقیت آغاز شد')
            }else{
                toast.error('ایجاد بازی جدید ناموفق بود')
                navigate('/')
            }
        }).finally(() => {
            if(!savedToDb)
                startGame()
        })
    }

    const startGameWithoutDeck = () => {
        const postedData = {
            title: `${game.title} - ${new Date().toLocaleDateString('fa-IR')}`,
            gameType: game._id,
            players: playersRoles,
            lastMoveCards: cards,
            isPrivate: true
        }
        post('games/create',postedData)
    }

    const getRandomIndex = max => {
        return parseInt(Math.random() * max)
    }

    const openRoleModal = roleBtn => {
        let index
        if(context.avatarClicked){
            const citizenRoles = []
            const getAllRoles = [...allRoles]
            getAllRoles.sort((a,b) => b.sort - a.sort).map(role => {
                if(role.side === 'citizen')
                    citizenRoles.push(role._id)
            })
            index = roles.indexOf(roles.filter(r => citizenRoles.includes(r))[0])
            if(index == -1)
                index = getRandomIndex(roles.length - 1)
            context.setAvatarClicked(false)
        }else
            index = getRandomIndex(roles.length - 1)
        const getRole = roles[index]
        const role = allRoles.find(role => role._id == getRole)
        roles.splice(index,1)
        const oldRoles = [...roles]
        const btnIndex = rolesButton.indexOf(roleBtn)
        const oldBtns = [...rolesButton]
        oldBtns.splice(btnIndex,1)
        setRoles(oldRoles)
        setRolesButton([...oldBtns])
        setCurrentRole({
            id: role._id,
            title: role.title,
            side: role.side,
            sort: role.sort,
            description: role.description
        })
        if(startType == 'role-with-submit-names' && !isIOS)
            setAutoFocus(true)
        setOpen(true)
    }

    const confirmRole = () => {
        const role = currentRole
        const newPlayer = {
            name: startType == 'role-with-submit-names' ? playerName : playersRoles.length + 1,
            roleTitle: role.title,
            side: role.side,
            sort: role.sort,
            status: 1,
            role: role.id
        }
        setPlayersRoles([...playersRoles,newPlayer])
        if(startType == 'role-with-submit-names')
            setPlayerName('')
        setAutoFocus(false)
        setOpen(false)
        if(roles.length == 0)
            setFinishAssignRoles(true)
    }

    useEffect(() => {
        if(finishAssignRoles){
            if(startType == 'role-with-submit-names'){
                startGame()
            }else{
                toast.success('تخصیص نقش ها تکمیل شد')
                navigate('/')
            }
        }
    },[finishAssignRoles])

    useEffect(() => {
        if(allRoles.length && !roles.length && startType == 'just-role')
            startGameWithoutDeck()
    },[roles])

    const closeModal = (e,reason) => {
        if(reason !== 'backdropClick')
            setOpen(false)
    }

    useEffect(() => {
        if(roles.length && !rolesBackup.length){
            const generateBackup = []
            allRoles.map(role => {
                if(roles.includes(role._id))
                    generateBackup.push(role)
            })
            setRolesBackup(generateBackup)
        }
    },[roles])

    const handleOpenSwitchModal = player => {
        setCurrentPlayerId(player._id)
        setOpenSwitchModal(true)
    }

    const changePlayerRole = () => {
        const role = rolesBackup.find(r => r._id == newRole)
        const postedData = {
            playerId: currentPlayerId,
            roleId: newRole,
            roleTitle: role.title,
            sort: role.sort
        }
        post('games/update-player',postedData).then(({data}) => {
            if(data.status)
                setPlayersRoles(data.result.players)
        }).finally(() => {
            setCurrentPlayerId('')
            setNewRole('')
            setOpenSwitchModal(false)
        })
    }

    const changePlayerStatus = playerId => {
        const postedData = {
            playerId
        }
        const oldPlayers = [...playersRoles]
        const index = oldPlayers.indexOf(playerId)
        if(index && oldPlayers[index]){
            oldPlayers[index].status = oldPlayers[index].status == 0 ? 1 : 0
            setPlayersRoles(oldPlayers)
        }
        post('games/update-player',postedData).then(({data}) => {
            if(data.status)
                setPlayersRoles(data.result.players)
        })
    }

    const handleEndGame = () => {
        const postedData = {
            id: gameId,
            hasEnd: true,
            winner
        }
        post('games/update',postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                navigate('/')
            }
        }).finally(() => {
            setOpenEndGameModal(false)
        })
    }

    const openCardModal = () => {
        const availableCards = [...cards.filter(card => card.status == 1)]
        const index = Math.floor(Math.random() * availableCards.length)
        const card = availableCards[index]
        setCurrentCard(card)
        availableCards[index].status = 0
        setShowCardModal(true)
        const postedData = {
            gameId: gameId,
            cardId: card._id
        }
        post('games/update-card',postedData)
    }

    const getPlayersList = () => {
        if(navigator.share){
            let players = ''
            playersRoles.map(player => players += `\r\n${player.name}`)
            navigator.share({
                title: 'لیست بازیکنان',
                text: 'لیست بازیکنان' + '\r\n' + players
            })
        }else
            toast.warning('این عملیات فقط در موبایل امکان پذیر است')
    }

    const isIOS = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '92%',
        boxSizing: 'border-box',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24
    }

    return (
        <div id="page-parent">
            {finishAssignRoles ? 
                <div id="page-parent-title">
                    <h4 onClick={getPlayersList} className="players-list-title">لیست بازیکنان</h4>
                    <span id="game-status" onClick={() => setOpenGameStatusModal(true)} className={cityStatus}>وضعیت شهر</span>
                    <Modal open={openGameStatusModal} onClose={() => setOpenGameStatusModal(false)} className="role-modal-box">
                        <Fade in={openGameStatusModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>وضعیت بازی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <p className="role-description custom-line-height">تعداد کل شهروندان : {playersRoles.filter(player => player.side == 'citizen').length}</p>
                                <p className="role-description custom-line-height">تعداد کل شهروندان زنده : {playersRoles.filter(player => player.side == 'citizen' && player.status == 1).length}</p>
                                <p className="role-description custom-line-height">تعداد کل شهروندان از بازی خارج شده : {playersRoles.filter(player => player.side == 'citizen' && player.status == 0).length}</p>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <p className="role-description custom-line-height">تعداد کل مافیا : {playersRoles.filter(player => player.side == 'mafia').length}</p>
                                <p className="role-description custom-line-height">تعداد کل مافیای زنده : {playersRoles.filter(player => player.side == 'mafia' && player.status == 1).length}</p>
                                <p className="role-description custom-line-height">تعداد کل مافیای از بازی خارج شده : {playersRoles.filter(player => player.side == 'mafia' && player.status == 0).length}</p>
                                {playersRoles.filter(player => player.side == 'independent').length ? <Divider sx={{ mt: 2,mb: 2 }} /> : ''}
                                {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل ها : {playersRoles.filter(player => player.side == 'independent').length}</p> : ''}
                                {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل های زنده : {playersRoles.filter(player => player.side == 'independent' && player.status == 1).length}</p> : ''}
                                {playersRoles.filter(player => player.side == 'independent').length ? <p className="role-description custom-line-height">تعداد کل مستقل های از بازی خارج شده : {playersRoles.filter(player => player.side == 'independent' && player.status == 0).length}</p> : ''}
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={() => setOpenGameStatusModal(false)}>متوجه شدم</Button>
                            </Box>
                        </Fade>
                    </Modal>
                    <Button id="handle-end-game" onClick={() => setOpenEndGameModal(true)}>اتمام بازی</Button>
                    <div className="main-area">
                        <Box sx={{ mb: 13 }}>
                            {cards.length ? <Divider sx={{ mb: 2 }}><Chip label="کارت های حرکت آخر" /></Divider> : ''}
                            {cards.length ? <div id="last-move-cards"><Accordion>
                                <AccordionSummary expandIcon={<i className="fal fa-chevron-down"></i>} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography>لیست کارت ها</Typography>
                                </AccordionSummary>
                                <Divider sx={{ mb: 1 }} />
                                <AccordionDetails>
                                    {cards.filter(card => card.status).length ? <Grid container spacing={2}>
                                        {cards.filter(card => card.status == 1).map((card,index) => <Grid key={index} item xs={3}><Button fullWidth className="role-btn" sx={{ py: 1.5 }} variant="contained" onClick={openCardModal}>{index + 1}</Button></Grid>)}
                                    </Grid> : <Alert variant="standard" color="info">کارتی باقی نمانده است</Alert>}
                                </AccordionDetails>
                            </Accordion></div> : ''}
                            <Divider sx={{ mb: 2 }}><Chip label={`اعضای تیم مافیا (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'mafia' && player.status == 1).length})`} /></Divider>
                            <Grid item xs={12} md={6}>
                                <List sx={{ p: 0 }}>
                                    {playersRoles.filter(player => player.side == 'mafia').sort((a,b) => a.sort - b.sort).map(player => <Card key={`mafia-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`mafia-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                        <ListItem secondaryAction={isSaved ? <><IconButton edge="end" onClick={() => handleOpenSwitchModal(player)}><i className="fal fa-pen"></i></IconButton><IconButton sx={{ ml: 2 }} edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton></> : ''}>
                                            <ListItemAvatar><Avatar><i className="fal fa-user-secret"></i></Avatar></ListItemAvatar>
                                            <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                        </ListItem>
                                    </Card>)}
                                </List>
                            </Grid>
                            {playersRoles.filter(player => player.side == 'citizen').length ? <Divider sx={{ my: 2 }}><Chip label={`اعضای تیم شهر (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'citizen' && player.status == 1).length})`} /></Divider> : ''}
                            <Grid item xs={12} md={6}>
                                <List sx={{ p: 0 }}>
                                    {playersRoles.filter(player => player.side == 'citizen').sort((a,b) => a.sort - b.sort).map(player => <Card key={`citizen-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`citizen-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                        <ListItem secondaryAction={isSaved ? <IconButton edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton> : ''}>
                                            <ListItemAvatar><Avatar><i className="fal fa-user"></i></Avatar></ListItemAvatar>
                                            <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                        </ListItem>
                                    </Card>)}
                                </List>
                            </Grid>
                            {playersRoles.filter(player => player.side == 'independent').length ? <Divider sx={{ my: 2 }}><Chip label={`اعضای ساید مستقل (افراد داخل بازی : ${playersRoles.filter(player => player.side == 'independent' && player.status == 1).length})`} /></Divider> : ''}
                            {playersRoles.filter(player => player.side == 'independent').length ? <Grid item xs={12} md={6}>
                                <List sx={{ p: 0 }}>
                                    {playersRoles.filter(player => player.side == 'independent').sort((a,b) => a.sort - b.sort).map(player => <Card key={`independent-${player._id || player.roleTitle}-${player.name}`} sx={{ mb: 1 }} className={`independent-card ${player.status == 0 ? 'card-player-dead' : ''}`} variant="elevation">
                                        <ListItem secondaryAction={isSaved ? <IconButton edge="end" onClick={() => changePlayerStatus(player._id)}><i className={`fal fa-heart${player.status == 0 ? '-crack' : ''}`}></i></IconButton> : ''}>
                                            <ListItemAvatar><Avatar><i className="fal fa-user-alien"></i></Avatar></ListItemAvatar>
                                            <ListItemText sx={{ color: '#fff' }} primary={player.name} secondary={player.roleTitle} />
                                        </ListItem>
                                    </Card>)}
                                </List>
                            </Grid> : ''}
                        </Box>
                    </div>
                    <Modal open={openSwitchModal} onClose={() => setOpenSwitchModal(false)} className="role-modal-box">
                        <Fade in={openSwitchModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>نقش جدید</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <FormControl fullWidth>
                                    <InputLabel>نقش جدید</InputLabel>
                                    <Select value={newRole} onChange={e => setNewRole(e.target.value)} label="نقش جدید">
                                        {rolesBackup.filter(role => role.side == 'mafia').map(role => <MenuItem key={`switch-${role._id}`} value={role._id}>{role.title}</MenuItem>)}
                                    </Select>
                                </FormControl>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={changePlayerRole} disabled={!newRole}>به روزرسانی نقش</Button>
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal open={openEndGameModal} onClose={() => setOpenEndGameModal(false)} className="role-modal-box">
                        <Fade in={openEndGameModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>اتمام بازی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <FormControl fullWidth>
                                    <InputLabel>برنده بازی</InputLabel>
                                    <Select value={winner} onChange={e => setWinner(e.target.value)} label="برنده بازی">
                                        <MenuItem value="citizen">شهروندان</MenuItem>
                                        <MenuItem value="mafia">مافیا</MenuItem>
                                        <MenuItem value="independent">مستقل</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={handleEndGame} disabled={!winner}>ذخیره اطلاعات</Button>
                            </Box>
                        </Fade>
                    </Modal>
                    <Modal open={showCardModal} onClose={() => setShowCardModal(false)} className="role-modal-box">
                        <Fade in={showCardModal}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>کارت انتخابی</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <Typography variant="h6" component="h3" sx={{ fontSize: 20,fontWeight: 'bold',textAlign: 'center',color: '#33487c',mb: 1 }}>{currentCard.title}</Typography>
                                <p className="role-description">{currentCard.description}</p>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={() => setShowCardModal(false)}>متوجه شدم</Button>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
             : <div id="page-parent-title">
                <h4>تخصیص نقش ها</h4>
                <div className="main-area">
                    <Grid container spacing={2}>
                        {rolesButton.map(role => <Grid key={role} item xs={3}><Button fullWidth className="role-btn" sx={{ py: 1.5 }} variant="contained" onClick={() => openRoleModal(role)}>{role}</Button></Grid>)}
                    </Grid>
                    <Modal open={open} onClose={(e,reason) => closeModal(e,reason)} disableEscapeKeyDown className="role-modal-box">
                        <Fade in={open}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>نقش شما</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <Typography variant="h6" component="h3" sx={{ fontSize: 20,fontWeight: 'bold',textAlign: 'center',color: currentRole.side == 'mafia' ? 'red' : (currentRole.side == 'citizen' ? '#28a824' : '#ee801f'),mb: 1 }}>{currentRole.side == 'mafia' ? 'مافیا' : (currentRole.side == 'citizen' ? 'شهروند' : 'مستقل')}</Typography>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 18,fontWeight: 'bold',textAlign: 'center',color: '#000',mb: 1 }}>{currentRole.title}</Typography>
                                <p className="role-description">{currentRole.description}</p>
                                {startType == 'role-with-submit-names' ? <TextField fullWidth sx={{ mt: 2,mb: 0.1 }} label="نام بازیکن" variant="outlined" value={playerName} autoFocus={autoFocus} onChange={e => setPlayerName(e.target.value)} /> : ''}
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={confirmRole} disabled={startType == 'role-with-submit-names' && !playerName}>متوجه شدم</Button>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
            </div>}
        </div>
    )
}

export default Start