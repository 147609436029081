import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import * as Yup from 'yup'
import { ThemeProvider,createTheme } from '@mui/material'
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { prefixer } from 'stylis'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { ContextProvider } from './context/Context'

Yup.setLocale({
  mixed: {
    required: 'وارد کردن این فیلد اجباری است',
  },
})

const theme = createTheme({
  direction: 'rtl'
})
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer,rtlPlugin]
})

if('serviceWorker' in navigator){
  navigator.serviceWorker.register('/sw.js',{
    updateViaCache: 'none',
    scope: '/'
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ContextProvider>
    <CacheProvider value={cacheRtl}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <ToastContainer autoClose={1500} />
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </CacheProvider>
  </ContextProvider>
);