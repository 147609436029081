import { useEffect, useState } from 'react'
import { useNavigate,useLocation } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material';

import './BottomNavigationBar.css'

const BottomNavigationBar = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [value,setValue] = useState(0)

    const handleNavigation = index => {
        setValue(index)
        switch(index){
            case 0:
                return navigate('/')
            case 1:
                return navigate('/tutorials')
            case 2:
                return navigate('/reserves')
            case 3:
                return navigate('/settings')
        }
    }

    useEffect(() => {
        const pathname = location.pathname
        switch(pathname){
            case '/':
                return setValue(0)
            case '/tutorials':
                return setValue(1)
            case '/reserves':
                return setValue(2)
            case '/settings':
                return setValue(3)
        }
    },[location.pathname])

    return (
        <div id="bottom-navigation-bar-parent">
            <BottomNavigation id="bottom-navigation-bar" showLabels value={value} onChange={(e,newValue) => handleNavigation(newValue)}>
                <BottomNavigationAction label="خانه" icon={<i className="fad fa-home"></i>} />
                <BottomNavigationAction label="آموزش ها" icon={<i className="fad fa-book"></i>} />
                <BottomNavigationAction label="رزرو ها" icon={<i className="fad fa-users"></i>} />
                <BottomNavigationAction label="تنظیمات" icon={<i className="fad fa-gear"></i>} />
            </BottomNavigation>
        </div>
    )
}

export default BottomNavigationBar