import { Alert } from '@mui/material'

const Reserves = () => {
    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>رزرو ها</h4>
                <Alert variant="standard" color="warning">قابلیت رزرو در نسخه 2.1 اضافه می‌گردد</Alert>
            </div>
        </div>
    )
}

export default Reserves