import { useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { post } from '../../utils/axios'
import { Alert, Box, Button } from '@mui/material'
import Slider from 'react-slick'

import './Home.css'

const Home = () => {

    const navigate = useNavigate()

    const [gameTypesLoading,setGameTypesLoading] = useState(true)
    const [gamesLoading,setGamesLoading] = useState(true)
    const [gameTypes,setGameTypes] = useState([])
    const [games,setGames] = useState([])
    const [sliders,setSliders] = useState([])
    const [slidersLoading,setSlidersLoading] = useState([])

    useEffect(() => {
        post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
        }).finally(() => setGameTypesLoading(false))
        post('games',{page: 1,limit: 5}).then(({data}) => {
            if(data.status)
                setGames(data.result)
        }).finally(() => setGamesLoading(false))
        post('sliders').then(({data}) => {
            if(data.status)
                setSliders(data.result)
        }).finally(() => setSlidersLoading(false))
    },[])

    const slidersSettings = {
        autoplay: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        rtl: true
    }

    return (
        <div id="page-parent">
            <div className="main-area">
                <Box sx={{ mb: games.length ? 2 : 5 }}>
                    <div id="page-parent-title">
                        <h4>شروع بازی جدید</h4>
                        <div id="game-types">
                            {gameTypesLoading ? [...Array(3)].map((item,index) => <div className="game-type-placehoder animate" key={`game-type-${index}`}></div>) : gameTypes.slice(0,3).map(gameType => <Button fullWidth variant="contained" className="game-type" key={gameType._id} sx={{ mb: 2 }} onClick={() => navigate(`/games/config/${gameType._id}`)}>
                                <img src={gameType.image ? `https://mafiabaza.com${gameType.image}` : '/assets/img/default-game-type.jpg'} />
                                <h5>{gameType.title}</h5>
                            </Button>)}
                            <Button fullWidth variant="outlined" className="all-game-types" onClick={() => navigate('/game-types')} sx={{ mt: 0.3 }} startIcon={<i className="fad fa-plus"></i>}>سایر سبک ها</Button>
                        </div>
                    </div>
                    {slidersLoading ? <div id="slider-placeholder" className="animate"></div> : <Slider {...slidersSettings}>
                        {sliders.map(slider => <div className="slider-item" key={slider._id}><img src={`https://mafiabaza.com${slider.image}`} alt={slider.title} /></div>)}
                    </Slider>}
                    <div id="latest-games">
                        <h4>5 بازی آخر</h4>
                        <span id="show-all" onClick={() => navigate('/games')}>مشاهده همه</span>
                        {gamesLoading ? [...Array(3)].map((item,index) => <div className="game-type-placehoder animate" key={`game-${index}`}></div>) : (games.length ? games.map(game => <Button fullWidth variant="contained" className="game-row" key={game._id} sx={{ mb: 2 }} onClick={() => navigate(`/games/${game._id}`)}>
                            <h5>{game.title}</h5>
                            {game.hasEnd ? <span className="game-has-end">تمام شده</span> : <span className="game-has-end white">باز</span>}
                        </Button>) : <Alert variant="standard" color="info">بازی یافت نشد</Alert>)}
                    </div>
                </Box>
            </div>
        </div>
    )
}

export default Home