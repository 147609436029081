import { useEffect,useState,useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid,Button,Typography,Modal,Fade,Box,Divider,TextField,Avatar,List,ListItem,ListItemAvatar,ListItemText,IconButton,Card,Chip,Select,MenuItem,FormControl,InputLabel,Accordion,AccordionSummary,AccordionDetails,Alert } from '@mui/material'
import { Context } from '../../context/Context'
import { toast } from 'react-toastify'
import { post } from '../../utils/axios'

const GuestStart = () => {

    const context = useContext(Context)
    const navigate = useNavigate()

    const [allRoles,setAllRoles] = useState([])
    const [rolesButton,setRolesButton] = useState([])
    const [roles,setRoles] = useState([])
    const [rolesBackup,setRolesBackup] = useState([])
    const [open,setOpen] = useState(false)
    const [currentRole,setCurrentRole] = useState({})
    const [finishAssignRoles,setFinishAssignRoles] = useState(false)

    useEffect(() => {
        if(!Object.keys(context.gameConfig).length)
            return navigate('/guest')
        setAllRoles(context.gameConfig.game.roles)
        setRoles(context.gameConfig.selectedRoles.sort((a, b) => 0.5 - Math.random()))
        const rolesButtonArr = []
        context.gameConfig.selectedRoles.map((role,index) => rolesButtonArr.push(index + 1))
        setRolesButton(rolesButtonArr)
        context.setGameConfig({})
    },[])

    const getRandomIndex = max => {
        return parseInt(Math.random() * max)
    }

    const openRoleModal = roleBtn => {
        let index = getRandomIndex(roles.length - 1)
        const getRole = roles[index]
        const role = allRoles.find(role => role._id == getRole)
        roles.splice(index,1)
        const oldRoles = [...roles]
        const btnIndex = rolesButton.indexOf(roleBtn)
        const oldBtns = [...rolesButton]
        oldBtns.splice(btnIndex,1)
        setRoles(oldRoles)
        setRolesButton([...oldBtns])
        setCurrentRole({
            id: role._id,
            title: role.title,
            side: role.side,
            sort: role.sort,
            description: role.description
        })
        setOpen(true)
    }

    const confirmRole = () => {
        setOpen(false)
        if(roles.length == 0)
            setFinishAssignRoles(true)
    }

    useEffect(() => {
        if(finishAssignRoles){
            toast.success('تخصیص نقش ها تکمیل شد')
            navigate('/guest')
        }
    },[finishAssignRoles])

    const closeModal = (e,reason) => {
        if(reason !== 'backdropClick')
            setOpen(false)
    }

    useEffect(() => {
        if(roles.length && !rolesBackup.length){
            const generateBackup = []
            allRoles.map(role => {
                if(roles.includes(role._id))
                    generateBackup.push(role)
            })
            setRolesBackup(generateBackup)
        }
    },[roles])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '92%',
        boxSizing: 'border-box',
        bgcolor: 'background.paper',
        borderRadius: '8px',
        boxShadow: 24
    }

    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>تخصیص نقش ها</h4>
                <div className="main-area">
                    <Grid container spacing={2}>
                        {rolesButton.map(role => <Grid key={role} item xs={3}><Button fullWidth className="role-btn" sx={{ py: 1.5 }} variant="contained" onClick={() => openRoleModal(role)}>{role}</Button></Grid>)}
                    </Grid>
                    <Modal open={open} onClose={(e,reason) => closeModal(e,reason)} disableEscapeKeyDown className="role-modal-box">
                        <Fade in={open}>
                            <Box sx={{...style,pt: 2,px: 2,pb: 2.2}}>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 15 }}>نقش شما</Typography>
                                <Divider sx={{ mt: 2,mb: 2 }} />
                                <Typography variant="h6" component="h3" sx={{ fontSize: 20,fontWeight: 'bold',textAlign: 'center',color: currentRole.side == 'mafia' ? 'red' : (currentRole.side == 'citizen' ? '#28a824' : '#ee801f'),mb: 1 }}>{currentRole.side == 'mafia' ? 'مافیا' : (currentRole.side == 'citizen' ? 'شهروند' : 'مستقل')}</Typography>
                                <Typography variant="h6" component="h3" sx={{ fontSize: 18,fontWeight: 'bold',textAlign: 'center',color: '#000',mb: 1 }}>{currentRole.title}</Typography>
                                <p className="role-description">{currentRole.description}</p>
                                <Button className="confirm-role" variant="contained" sx={{ py: 1 }} onClick={confirmRole}>متوجه شدم</Button>
                            </Box>
                        </Fade>
                    </Modal>
                </div>
            </div>
        </div>
    )
}

export default GuestStart