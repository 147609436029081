import { Alert } from "@mui/material"

const Settings = () => {
    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>تنظیمات</h4>
                <Alert variant="standard" color="warning">قابلیت تنظیمات در نسخه 2.1 اضافه می‌گردد</Alert>
            </div>
            <p id="current-version">نسخه فعلی : 2.0</p>
        </div>
    )
}

export default Settings