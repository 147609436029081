import './AddToHomeScreen.css'

const AddToHomeScreen = () => {
    return (
        <>
            <div id="add-to-home-screen">
                <h2>نصب اپلیکیشن مافیابازا</h2>
                <img id="add-to-home-screen-logo" src="/assets/img/logo-horizontal.png" />
                <p>1 - بر روی دکمه <i className="fal fa-arrow-up-from-bracket"></i> در پایین گوشی کلیک کنید.</p>
                <p>2 - منوی باز شده را به بالا اسکرول کنید و بر روی دکمه <img src="/assets/img/add-to-homescreen.jpg" /> کلیک کنید.</p>
                <p>3 - از بالای صفحه بر روی دکمه Add کلیک کنید.</p>
                <p>4 - لطفاً فقط از مرورگر Safari استفاده کنید.</p>
            </div>
            <i className="fal fa-arrow-down add-to-home-screen-arrow"></i>
        </>
    )
}

export default AddToHomeScreen