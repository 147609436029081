import { useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { post } from '../../utils/axios'
import { Button,Box } from '@mui/material'

import './GameTypes.css'

const GameTypes = ({ newGameType = true,url = '/games/config' }) => {

    const navigate = useNavigate()

    const [gameTypesLoading,setGameTypesLoading] = useState(true)
    const [gameTypes,setGameTypes] = useState([])

    useEffect(() => {
        post('game-types').then(({data}) => {
            if(data.status)
                setGameTypes(data.result)
        }).finally(() => setGameTypesLoading(false))
    },[])
    
    return (
        <div id="page-parent">
            <div id="page-parent-title">
                <h4>سبک ها</h4>
                <div className="main-area">
                    <Box sx={{ mb: newGameType ? 10 : 5 }}>
                        <div id="game-types">
                            {gameTypesLoading ? [...Array(3)].map((item,index) => <div className="game-type-placehoder animate" key={`game-type-${index}`}></div>) : gameTypes.map(gameType => <Button fullWidth variant="contained" className="game-type" key={gameType._id} sx={{ mb: 2 }} onClick={() => navigate(`${url}/${gameType._id}`)}>
                                <img src={gameType.image ? `https://mafiabaza.com${gameType.image}` : '/assets/img/default-game-type.jpg'} />
                                <h5>{gameType.title}</h5>
                            </Button>)}
                            {!!newGameType && <Button fullWidth variant="outlined" className="all-game-types" disabled startIcon={<i className="fad fa-plus"></i>}>
                            ایجاد سبک جدید
                            <span className="coming-soon-badge">به زودی</span>
                            </Button>}
                        </div>
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default GameTypes