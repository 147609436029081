import { useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid, TextField } from '@mui/material'
import Button from '../../components/Button'
import { post } from '../../utils/axios'
import { toast } from 'react-toastify'

import './Verify.css'

const Verify = () => {

    const navigate = useNavigate()

    const [phone,setPhone] = useState(localStorage.getItem('phone'))
    const [type,setType] = useState(localStorage.getItem('type'))
    const [isLoading,setIsLoading] = useState(false)
    const [otp,setOtp] = useState('')
    const [otp1,setOtp1] = useState('')
    const [otp2,setOtp2] = useState('')
    const [otp3,setOtp3] = useState('')
    const [otp4,setOtp4] = useState('')
    const [otp5,setOtp5] = useState('')
    const [counter,setCounter] = useState(120)

    const startCounter = () => {
        setCounter(120)
        let interCounter = 120
        const interval = setInterval(() => {
            if(interCounter > 0){
                interCounter -= 1
                setCounter(interCounter)
            }
            if(interCounter == 0)
                clearInterval(interval)
        },1000)
    }

    const sendAgain = async () => {
        const postedData = {
            phone
        }
        const url = type == 'login' ? 'auth/login/resend' : 'auth/register/resend'
        post(url,postedData).then(({data}) => {
            if(data.status){
                toast.success(data.message)
                startCounter()
                setOtp1('')
                setOtp2('')
                setOtp3('')
                setOtp4('')
                setOtp5('')
                setOtp('')
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    useEffect(() => {
        localStorage.removeItem('phone')
        localStorage.removeItem('type')
        if(!phone || !type)
            return navigate('/auth')
        startCounter()
    },[])

    const persianNumbers = ['۰','۱','۲','۳','۴','۵','۶','۷','۸','۹']

    const changeToEnglish = (number,method) => {
        if(number.includes('۰'))
            method('0')
        if(number.includes('۱'))
            method('1')
        if(number.includes('۲'))
            method('2')
        if(number.includes('۳'))
            method('3')
        if(number.includes('۴'))
            method('4')
        if(number.includes('۵'))
            method('5')
        if(number.includes('۶'))
            method('6')
        if(number.includes('۷'))
            method('7')
        if(number.includes('۸'))
            method('8')
        if(number.includes('۹'))
            method('9')
    }

    useEffect(() => {
        if(persianNumbers.includes(otp1))
            changeToEnglish(otp1,setOtp1)
        if(persianNumbers.includes(otp2))
            changeToEnglish(otp2,setOtp2)
        if(persianNumbers.includes(otp3))
            changeToEnglish(otp3,setOtp3)
        if(persianNumbers.includes(otp4))
            changeToEnglish(otp4,setOtp4)
        if(persianNumbers.includes(otp5))
            changeToEnglish(otp5,setOtp5)
        setOtp(otp1.toString() + otp2.toString() + otp3.toString() + otp4.toString() + otp5.toString()) 
    },[otp1,otp2,otp3,otp4,otp5])

    useEffect(() => {
        if(otp.length == 5)
            verify()
    },[otp])

    const verify = e => {
        if(e)
            e.preventDefault()
        setIsLoading(true)
        const postedData = {
            phone,
            otp
        }
        const url = type == 'login' ? 'auth/verify' : 'auth/register/verify'
        post(url,postedData).then(({data}) => {
            if(data.status){
                localStorage.setItem('token',data.result.token)
                toast.success(data.message)
                setTimeout(() => {
                    navigate('/')
                },300)
            }
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const getCodeFromSms = otp => {
        otp = otp.toString()
        setOtp1(otp[0])
        setOtp2(otp[1])
        setOtp3(otp[2])
        setOtp4(otp[3])
        setOtp5(otp[4])
    }

    if('OTPCredential' in window){ 
        const ac = new AbortController();
        navigator.credentials.get({
            otp: { transport:['sms'] },
            signal: ac.signal
        }).then(otp => {
            if(otp.code)
                getCodeFromSms(otp.code)
        });
    }

    const goToNextInput = (e,inputId) => {
        const key = e.keyCode || e.charCode
        if(key == 8 || key == 46){
            if(inputId != 1)
                document.getElementById(`otp-${inputId - 1}`).focus()
        }else{
            if(inputId != 5 && e.target.value.length === 1)
                document.getElementById(`otp-${inputId + 1}`).focus()
        }
    }

    const prevent = e => {
        const key = parseInt(e.key)
        const keyCode = e.keyCode || e.charCode
        if(!/^\d+$/.test(key) && keyCode != 8 && keyCode != 46)
            e.preventDefault()
    }

    return (
        <Grid>
            <form onSubmit={e => verify(e)}>
                <h2 className="auth-page-title">اعتبارسنجی</h2>
                <span className="auth-page-description">کد اعتبارسنجی که به شماره {phone} ارسال شده است را در کادر زیر وارد کنید</span>
                <div className="auth-input-group" dir="ltr">
                    <TextField type="tel" autoComplete="off" autoFocus={true} hiddenLabel sx={{width: '17%'}} inputProps={{maxLength: 1,id: 'otp-1'}} onKeyDown={e => prevent(e)} onKeyUp={e => goToNextInput(e,1)} value={otp1} onChange={e => setOtp1(e.target.value)} />
                    <TextField type="tel" autoComplete="off" hiddenLabel sx={{width: '17%'}} inputProps={{maxLength: 1,id: 'otp-2'}} onKeyDown={e => prevent(e)} onKeyUp={e => goToNextInput(e,2)} value={otp2} onChange={e => setOtp2(e.target.value)} />
                    <TextField type="tel" autoComplete="off" hiddenLabel sx={{width: '17%'}} inputProps={{maxLength: 1,id: 'otp-3'}} onKeyDown={e => prevent(e)} onKeyUp={e => goToNextInput(e,3)} value={otp3} onChange={e => setOtp3(e.target.value)} />
                    <TextField type="tel" autoComplete="off" hiddenLabel sx={{width: '17%'}} inputProps={{maxLength: 1,id: 'otp-4'}} onKeyDown={e => prevent(e)} onKeyUp={e => goToNextInput(e,4)} value={otp4} onChange={e => setOtp4(e.target.value)} />
                    <TextField type="tel" autoComplete="off" hiddenLabel sx={{width: '17%'}} inputProps={{maxLength: 1,id: 'otp-5'}} onKeyDown={e => prevent(e)} onKeyUp={e => goToNextInput(e,5)} value={otp5} onChange={e => setOtp5(e.target.value)} />
                </div>
                <Button type="submit" fullWidth isLoading={isLoading} sx={{ py: 1.3,mt: 2.5,mb: 1.5 }} className="btn-primary" variant="contained">احراز هویت</Button>
                {counter != 0 ? <p className="auth-page-footer">{counter} ثانیه تا ارسال مجدد</p> : <p className="auth-page-footer cursor-pointer color-primary" onClick={sendAgain}>ارسال مجدد</p>}
            </form>
        </Grid>
    )
}

export default Verify